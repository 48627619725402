import React, { Component } from 'react';
import {Image} from 'react-bootstrap';

import './MediaBar.css';

class MediaBar extends React.Component {

    propTypes: {
        onChange:   React.PropTypes.func,
        Pause:      React.PropTypes.func,
        hideBar:    React.PropTypes.func,
        showBar:    React.PropTypes.func
    }

    constructor(props) {
        super(props);
        this.mediaBarWidth = React.createRef()

        this.state = {
            value: 0,
            seconds: 0,
            duration: 0,
            isPlaying: false,
            downButtonActive: true
        };
    }

    isPlaying() {
        return (this.state.isPlaying);
    }

    setValue(value, seconds, duration) {
        //console.log("Setting value: " + value);
        this.setState({
            value: value,
            seconds: seconds,
            duration: duration
        });
    }

    setPaused() {
        //console.log("Media Bar .setPaused");
        this.setState({
            isPlaying: false
        });
    }

    setPlaying() {
        this.setState({
            isPlaying: true
        });

        if (typeof this.props.onChange === 'function') {
            this.props.onChange(this.state.value);
        }
    }

    renderPlayButton()
    {
        if (this.state.isPlaying)
            return (
                <Image src="pause.png" width="24" />
            )
        else
            return (
                <Image src="play.png" width="24" />
            )
    }

    renderDownButton()
    {
        if (this.state.downButtonActive)
            return (
                <Image src="down.png" width="24" />
            )
        else
            return (
                <Image src="up.png" width="24" />
            )
    }

    renderTimeInfo() {

        if (isNaN(parseFloat(this.state.seconds)) || isNaN(parseFloat(this.state.duration)))
        {
            return ("Loading...");
        }
        else
        {
            return (parseFloat(this.state.seconds).toFixed(1) + " of " + parseFloat(this.state.duration).toFixed(1) + "[s] at " + parseFloat(this.state.value).toFixed(1) + '%');
        }
    }

    render() {
        const style = {
            width: this.state.value + '%'
        };

        return (
            <div ref={this.mediaBarWidth} className="mediaBar" onClick={(e) => { this.handleClick(e); }} >
            <div className="bar" style={style} />
            <div className="hider">{ this.renderDownButton() }</div>
            <div className="mediaInfo">{ this.renderPlayButton() } {this.renderTimeInfo()}</div>
            </div>
        );
    }

    handleClick(e) {

        if (this.state.isPlaying)
        {
            this.setState({
                isPlaying: false
            });

            //console.log('onpause');

            if (typeof this.props.Pause === 'function') {
                this.props.Pause();
            }
        }
        else
        {
            if (e.clientX <= 24)
            {
                if (this.state.downButtonActive)
                {
                    this.setState({
                        downButtonActive: false
                    });

                    if (typeof this.props.hideBar === 'function') {
                        this.props.hideBar();
                    }
                }
                else
                {
                    this.setState({
                        downButtonActive: true
                    });

                    if (typeof this.props.showBar === 'function') {
                        this.props.showBar();
                    }
                }
                //console.log("the down button has been pressed!");
            }
            else
            {
                const relativeWidth = 100 * e.clientX / this.mediaBarWidth.current.offsetWidth;
                this.setState({
                    value: relativeWidth,
                    isPlaying: true
                });

                if (typeof this.props.onChange === 'function') {
                    this.props.onChange(relativeWidth);
                }
            }
        }
    }
}
export default MediaBar;
