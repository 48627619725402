import React, { Component } from 'react';

import { Button, Modal, Pagination, Table } from 'react-bootstrap';

import UserAdd from './UserAdd.js';
import UserUpdate from './UserUpdate.js';

import './UserList.css';

class UserList extends Component {

    constructor(props) {
        super(props);

        let user = {
            username: "",
            lastLoggin: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            categories: []
        }

        this.state = {
            users: [],
            showAddUser: false,
            startIndex: 0,
            pageSize: 5,
            errMsg: '',
            showUpdateUser: false,
            userToUpdate: user
        };

        this.frmAddUser = React.createRef();
    }

    componentDidMount() {
        //console.log("componentDidMount.user_list");

        fetch('user_list', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "startIndex=" + this.state.startIndex + "&pageSize=" + this.state.pageSize
        })
        .then(res => res.json())
        .then((data) => {

            //console.log("data");
            //console.log(data);

            for (var i = 0; i < data.length; i++)
            {
                if (data[i].categories == 'null')
                {
                    //console.log("is nullified");
                }
                else
                {
                    //const obj = JSON.parse('{"name":"John", "age":30, "city":"New York"}');
                    //var strObj = "[{\"categoryId\":\"20\",\"title\":\"Roadscans\"},{\"categoryId\":\"23\",\"title\":\"Volumetrics\"}]";
                    var strObj = data[i].categories;
                    //console.log(strObj);

                    const obj = JSON.parse(strObj);
                    //console.log(obj);
                    data[i].categories = obj;
                }
            }

            this.setState({
                users:data
            });

        })
    }

    reloadList = () => {
        //console.log("reloadList.user_list");

        fetch('user_list', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "startIndex=" + this.state.startIndex + "&pageSize=" + this.state.pageSize
        })
        .then(res => res.json())
        .then((data) => {

            //console.log("data");
            //console.log(data);

            for (var i = 0; i < data.length; i++)
            {
                if (data[i].categories == 'null')
                {
                    //console.log("is nullified");
                }
                else
                {
                    //const obj = JSON.parse('{"name":"John", "age":30, "city":"New York"}');
                    //var strObj = "[{\"categoryId\":\"20\",\"title\":\"Roadscans\"},{\"categoryId\":\"23\",\"title\":\"Volumetrics\"}]";
                    var strObj = data[i].categories;
                    //console.log(strObj);

                    const obj = JSON.parse(strObj);
                    //console.log(obj);
                    data[i].categories = obj;
                }
            }

            this.setState({
                users:data
            });
        })
    }

    reloadUsers = () => {
        console.log("reload users!");
    }

    handleDelete = username => event => {
        event.preventDefault();

        fetch('user_delete', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "username=" + username
        })
        .then(res => res.json())
        .then(data =>
        {
            if ('true' === data[0].delete)
            {
                //console.log("handleDelete.user_list");

                fetch('user_list', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                    },
                    body: "startIndex=" + this.state.startIndex + "&pageSize=" + this.state.pageSize
                })
                .then(res => res.json())
                .then((data) => {

                    //console.log("data");
                    //console.log(data);

                    for (var i = 0; i < data.length; i++)
                    {
                        if (data[i].categories == 'null')
                        {
                            //console.log("is nullified");
                        }
                        else
                        {
                            //const obj = JSON.parse('{"name":"John", "age":30, "city":"New York"}');
                            //var strObj = "[{\"categoryId\":\"20\",\"title\":\"Roadscans\"},{\"categoryId\":\"23\",\"title\":\"Volumetrics\"}]";
                            var strObj = data[i].categories;
                            //console.log(strObj);

                            const obj = JSON.parse(strObj);
                            //console.log(obj);
                            data[i].categories = obj;
                        }
                    }

                    this.setState({
                        users:data
                    });
                })
            }
        })
        .catch(console.log)
    }

    showUpdateUser = (user) => {
        console.log("Show update user");
        console.log(user);
        this.setState({
            userToUpdate: user,
            showUpdateUser: true,
            errMsg: ''
        });
    }

    hideUpdateUser = () => {
        this.setState({
            showUpdateUser: false,
            errMsg: ''
        });
    }

    handleUpdateUser = () => {
        let userToUpdate = this.state.userToUpdate;

        //console.log("updateUser");
        //console.log(userToUpdate);

        this.setState({
            showUpdateUser: false,
            errMsg: ''
        });

        var categoryString = "";
        for (var i = 0; i < userToUpdate.categories.length; i++)
        {
            categoryString += userToUpdate.categories[i].categoryId + ",";
        }
        categoryString = categoryString.substring(0, categoryString.length-1);

        fetch('user_update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "username=" + userToUpdate.username +
                  "&firstName=" + userToUpdate.firstName +
                  "&lastName=" + userToUpdate.lastName +
                  "&email=" + userToUpdate.email +
                  "&phone=" + userToUpdate.phone +
                  "&categories=" + categoryString
        })
        .then(res => res.json())
        .then(data =>
        {
        })
        .catch(console.log)

    }

    handleUserUpdateFirstName = (e) => {
        let user = this.state.userToUpdate;
        user.firstName = e.target.value;
        this.setState({
            userToUpdate: user
        });
    }

    handleUserUpdateLastName = (e) => {
        let user = this.state.userToUpdate;
        user.lastName = e.target.value;
        this.setState({
            userToUpdate: user
        });
    }

    handleUserUpdateEmail = (e) => {
        let user = this.state.userToUpdate;
        user.email = e.target.value;
        this.setState({
            userToUpdate: user
        });
    }

    handleUserUpdatePhone = (e) => {
        let user = this.state.userToUpdate;
        user.phone = e.target.value;
        this.setState({
            userToUpdate: user
        });
    }

    handleUserUpdateCategories = (e) => {
        //console.log("handleUserUpdateCategories");
        //console.log(e);
        let user = this.state.userToUpdate;
        var categories = [];

        for (var i = 0; i < e.length; i++)
        {
            var opt = {
                categoryId: e[i].value,
                title: e[i].label
            };

            categories.push(opt);
        }

        user.categories = categories;
        this.setState({
            userToUpdate: user
        });
    }


    renderListData = () => {
        return this.state.users.map((user) => (
            <tr class="blankBody">
                <td class="blankCell"></td>
                <td onClick={() => this.showUpdateUser(user)}>{user.username}</td>
                <td>{user.lastLoggin}</td>
                <td><form class="form-inline" name="frmLogout" onSubmit={this.handleDelete(user.username)}><Button className="btnUser" size="lg" type="submit">Delete</Button><input type="hidden" name="filename" value={user.username} /></form></td>
                <td class="blankCell"></td>
            </tr>
        ));
    }

    handleAddUser = () => {
        this.setState({
            showAddUser: true,
            errMsg: ''
        });
    }

    handleCloseAddUser = () => {
        this.setState({ showAddUser: false });
    }

    handleSubmitAddUser = () => {
        //console.log("Submitting user!");
        //console.log(this.frmAddUser.current.GetUsername());

        fetch('user_add', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "username=" + this.frmAddUser.current.GetUsername() + "&password=" + this.frmAddUser.current.GetPassword() + "&firstName=" + this.frmAddUser.current.GetFirstname() + "&lastName=" + this.frmAddUser.current.GetLastname() + "&email=" + this.frmAddUser.current.GetEmail() + "&phone=" + this.frmAddUser.current.GetPhone()
        })
        .then(res => res.json())
        .then(data =>
        {
            if ('true' === data[0].add)
            {
                this.setState({ showAddUser: false });
                this.props.reloadUsers();
            }
            else
            {
                this.setState({
                    errMsg: data[0].msg
                });
            }
        })
    }

    renderPagination = () => {
        let numPages = 1 + this.props.numUsers / this.state.pageSize;
        let items = [];
        let active = this.state.pageId;

        var iStart = active - 5;
        var iFinish = active + 5;

        if (iStart < 1)
            iStart = 1;

        if (iFinish > numPages)
            iFinish = numPages;

        //for (let number = 1; number <=numPages; number++)
        for (let number = iStart; number <=iFinish; number++)
        {
            items.push(
                <Pagination.Item className="pageEvents" key={number} active={number === active}>
                {number}
                </Pagination.Item>,
            );
        }

        return (
            <Pagination onClick={this.loadPage}>{items}</Pagination>
        )
    }

    loadPage = e => {
        if (typeof e.target.text !== "undefined")
        {
            let newPageId = parseInt(e.target.text);
            let newStartIndex = (newPageId-1) * this.state.pageSize;

            this.setState({
                pageId: newPageId,
                startIndex: newStartIndex
            });

            //console.log("loadPage.user_list");
            fetch('user_list', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: "startIndex=" + this.state.startIndex + "&pageSize=" + this.state.pageSize
            })
            .then(res => res.json())
            .then((data) => {

                //console.log("data");
                //console.log(data);

                for (var i = 0; i < data.length; i++)
                {
                    if (data[i].categories == 'null')
                    {
                        //console.log("is nullified");
                    }
                    else
                    {
                        //const obj = JSON.parse('{"name":"John", "age":30, "city":"New York"}');
                        //var strObj = "[{\"categoryId\":\"20\",\"title\":\"Roadscans\"},{\"categoryId\":\"23\",\"title\":\"Volumetrics\"}]";
                        var strObj = data[i].categories;
                        //console.log(strObj);

                        const obj = JSON.parse(strObj);
                        //console.log(obj);
                        data[i].categories = obj;
                    }
                }

                this.setState({
                    users:data
                });
            })
        }
    }

    render() {
        return (
            <div>

            <Modal
              show={this.state.showAddUser}
              onHide={this.handleCloseAddUser}
              backdrop="static"
              centered
            >
            <Modal.Header closeButton>
                <Modal.Title><center><h1 class="pageTitle">Add New User</h1></center></Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <UserAdd ref={this.frmAddUser} reloadUsers={this.reloadUsers} />
            </Modal.Body>

            <Modal.Footer>
                <span className="userAddErr">{this.state.errMsg}</span>
                <Button onClick={this.handleSubmitAddUser}>Add</Button>
                <Button onClick={this.handleCloseAddUser}>Close</Button>
            </Modal.Footer>
            </Modal>

            <UserUpdate
            showUpdateUser={this.state.showUpdateUser}
            hideUpdateUser={this.hideUpdateUser}
            handleUpdateUser={this.handleUpdateUser}
            userToUpdate={this.state.userToUpdate}
            handleUserUpdateFirstName={this.handleUserUpdateFirstName}
            handleUserUpdateLastName={this.handleUserUpdateLastName}
            handleUserUpdateEmail={this.handleUserUpdateEmail}
            handleUserUpdatePhone={this.handleUserUpdatePhone}
            handleUserUpdateCategories={this.handleUserUpdateCategories}>
            </UserUpdate>

            <div class="paginationUsers">{this.renderPagination()}</div>
            <Table className="tblRecordingFiles">
            <thead>
                <tr>
                    <th class="userAbsorbingColumn"></th>
                    <th><h3>User</h3></th>
                    <th><h3>Last Login</h3></th>
                    <th><h3>Action</h3></th>
                    <th class="userAbsorbingColumn"></th>
                </tr>
            </thead>
            {this.renderListData()}
            </Table>

            <Button onClick={this.handleAddUser} className="btnUser">
            Add User
            </Button>

            </div>
        )
    }
}

export default UserList;
