import React, { Component } from 'react';

import { Alert, Button, Table, Jumbotron, Pagination } from 'react-bootstrap';

import ProcessAdd from './ProcessAdd.js';
import ProcessChange from './ProcessChange.js';

import Endpoint from './Endpoint.js';

import './Process.css';

class Process extends Component {

    constructor(props) {
        super(props);

        let process = {
            ProcessId: -1,
            Name: "",
            Description: "",
            Command: ""
        }

        this.state = {
            list: [],
            errMsg: '',
            showAddProcess: false,
            addProcessErrMsg: '',
            renderAlert: false,
            alertMsg: '',
            alertSuccess: false,
            showUpdateProcess: false,
            processToUpdate: process
        };
    }

    componentDidMount() {
        fetch('process_list')
        .then(res => res.json())
        .then((data) => {
            this.setState({
                list: data
            });
        })
        .catch(console.log)
    }

    reloadPage = () => {
        this.refs.mapList.reloadPage();
    }

    renderPagination = () => {
        let numPages = Math.ceil((this.props.numClouds) / this.state.pageSize);
        let items = [];
        let active = this.state.pageId;

        var iStart = active - 10;
        var iFinish = active + 10;

        if (iStart < 1)
            iStart = 1;

        if (iFinish > numPages)
            iFinish = numPages;

        //for (let number = 1; number <=numPages; number++)
        for (let number = iStart; number <=iFinish; number++)
        {
            items.push(
                <Pagination.Item className="pageClouds" key={number} active={number === active}>
                {number}
                </Pagination.Item>,
            );
        }

        return (
            <Pagination onClick={this.loadPage}>{items}</Pagination>
        )
    }

    handleDelete = (processId) => event => {
        event.preventDefault();

        fetch('process_delete', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "processId=" + processId
        })
        .then(res => res.json())
        .then(data =>
        {
            if ('true' === data[0].delete)
            {
                fetch('process_list')
                .then(res => res.json())
                .then((data) => {
                    this.setState({
                        list: data
                    });
                })
                .catch(console.log);
            }
            else
            {
                this.setState({
                    renderAlert: true,
                    alertMsg: data[0].msg,
                    alertSuccess: false
                });

                setTimeout(this.hideAlert, 3000)
            }
        })
        .catch(console.log)

    }

    renderHeader = () => {
        return (
            <thead>
                <tr>
                    <th><h3>Process</h3></th>
                    <th><h3>Name</h3></th>
                    <th><h3>Description</h3></th>
                    <th><h3>Command</h3></th>
                    <th></th>
                </tr>
            </thead>
        )
    }

    renderListData = () => {
        return this.state.list.map((process) => (
            <tbody>
                <tr>
                    <td className="processList" onClick={() => this.showUpdateProcess(process)}>{process.ProcessId}</td>
                    <td className="processList" onClick={() => this.showUpdateProcess(process)}>{process.Name}</td>
                    <td className="processList" onClick={() => this.showUpdateProcess(process)}>{process.Description}</td>
                    <td className="processList" onClick={() => this.showUpdateProcess(process)}>{process.Command}</td>
                    <td className="processList">
                        <form name="frmDelete" onSubmit={this.handleDelete(process.ProcessId)}>
                        <Button variant="danger" size="lg" type="submit">Delete</Button>
                        </form>
                    </td>
                </tr>
            </tbody>
        ))
    }

    reloadProcesses = () => {
        //console.log("reloadProcesses");
        fetch('process_list')
        .then(res => res.json())
        .then((data) => {
            this.setState({
                list: data
            });
        })
        .catch(console.log)
    }

    showAddProcess = () => {
        this.setState({
            showAddProcess: true
        });
    }

    hideAddProcess = () => {
        this.setState({
            showAddProcess: false
        });
    }

    hideAlert = () =>
    {
        this.setState({
            renderAlert: false
        });
    }

    renderAlert = () =>
    {
        if (this.state.renderAlert)
        {
            if (this.state.alertSuccess)
            {
                return (<Alert key="success" variant="success">
                            {this.state.alertMsg}
                        </Alert>);
            }
            else
            {
                return (<Alert key="danger" variant="danger">
                            {this.state.alertMsg}
                        </Alert>);
            }
        }

    }

    reloadSettings = () => {
        this.refs.categorylist.reloadList(this.state.pageId, this.state.pageSize);
    }

    hideUpdateProcess = () => {
        this.setState({
            showUpdateProcess: false,
            errMsg: ''
        });
    }

    showUpdateProcess = (process) => {
        //console.log("Show update process");
        //console.log(process);
        this.setState({
            processToUpdate: process,
            showUpdateProcess: true,
            errMsg: ''
        });
    }

    handleUpdateName = (e) => {
        let process = this.state.processToUpdate;
        process.Name = e.target.value;
        this.setState({
            processToUpdate: process
        });
    }

    handleUpdateDescription = (e) => {
        let process = this.state.processToUpdate;
        process.Description = e.target.value;
        this.setState({
            processToUpdate: process
        });
    }

    handleUpdateCommand = (e) => {
        let process = this.state.processToUpdate;
        process.Command = e.target.value;
        this.setState({
            processToUpdate: process
        });
    }

    handleUpdateProcess = () => {
        let process = this.state.processToUpdate;

        console.log("updateProcess");
        console.log(process);

        this.setState({
            processToUpdate: process
        });

        fetch('process_update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "ProcessId=" + process.ProcessId + "&Name=" + process.Name + "&Description=" + process.Description + "&Command=" + process.Command
        })
        .then(res => res.json())
        .then(data =>
        {
            if ('true' === data[0].update)
            {
                this.setState({
                    errMsg: data[0].msg,
                    showUpdateProcess: false
                });
            }
            else
            {
                this.setState({
                    errMsg: data[0].msg,
                    showUpdateProcess: false
                });
            }

            this.reloadProcesses();
        })
    }

    render() {
        return (<div>

                <ProcessChange
                showUpdateProcess={this.state.showUpdateProcess}
                hideUpdateProcess={this.hideUpdateProcess}
                handleUpdateProcess={this.handleUpdateProcess}
                handleUpdateName={this.handleUpdateName}
                handleUpdateDescription={this.handleUpdateDescription}
                handleUpdateCommand={this.handleUpdateCommand}
                process={this.state.processToUpdate}>
                </ProcessChange>

                <ProcessAdd hideAddProcess={this.hideAddProcess} showAddProcess={this.state.showAddProcess} reloadProcesses={this.reloadProcesses}></ProcessAdd>

                <Jumbotron className="jumboHeader">
                <center><h1 class="process-title">Process</h1></center>
                <Button className="btnAddProcess" size="lg" onClick={this.showAddProcess}>Add Process</Button>
                </Jumbotron>
                <div class="paginationClouds">{this.renderPagination()}</div>
                <Table className="pipelineList">
                {this.renderHeader()}
                {this.renderListData()}
                </Table>
                {this.renderAlert()}

                //<Jumbotron className="jumboHeader">
                //<center><h1 class="pageTitle">EndPoint</h1></center>
                //</Jumbotron>
                //<Endpoint reloadSettings={this.reloadSettings} ref="endpoint"/>
                </div>)
    }
}

export default Process;
