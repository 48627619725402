import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Col, Container, ProgressBar, Row } from 'react-bootstrap';

import './App.css';

import TopBar from './TopBar.js'
import Login from './Login.js';
import Setup from './Setup.js';

import CloudFiles from './Files/CloudFiles.js';

import SettingsPage from './Settings/SettingsPage.js';

import HistoryPage from './History/HistoryPage.js';

import ProfileEdit from './Profile/ProfileEdit.js';

import Pipeline from './Pipeline/Pipeline.js';
import Process from './Pipeline/Process.js';
import Workflows from './Workflows/Workflows.js';

import Map from './Map/Map.js';

import UserPage from './Users/UserPage.js';


const NavStates = {
    MAP_FILES  : 0,
    PIPELINE   : 1,
    PROCESS    : 2,
    WORKFLOWS  : 3,
    CATEGORIES : 4,
    HISTORY    : 5,
    PROFILE    : 6,
    USERS      : 7,
    MAP        : 8
}

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            mapFile: null,
            userValid: false,
            username: '',
            accessLevel: 0,
            errorMsg: '',
            navState: NavStates.MAP_FILES,
            activeTransferVisible: false,
            activeTransferPercentage: 0,
            activeTransferText: ''
        };
    }

    componentDidMount() {
        fetch('status', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            }
        })
        .then(res => res.json())
        .then((data) =>
        {
            //console.log("App.componentDidMount.login = " + data[0].login);
            if (data[0].login === 'true')
            {
                this.setState({
                    userValid: true,
                    username: data[0].username,
                    accessLevel: parseInt(data[0].accessLevel),
                    errorMsg: data[0].errorMsg
                });

                this.startEventSource();
            }
            else
            {
                this.setState({
                    userValid: false,
                    username: '',
                    accessLevel: parseInt(data[0].accessLevel),
                    errorMsg: data[0].errorMsg
                });
            }
        })
        .catch(console.log)
    }

    componentWillMount() {
        //console.log('componentWillMount');
    }

    isLoggedIn = (username, accessLevel) => {

        this.setState({
            userValid: true,
            username: username,
            accessLevel: parseInt(accessLevel)
        });

        this.startEventSource();
    }

    isLoggedOut = (username, accessLevel) => {
        this.setState({
            userValid: false,
            username: username,
            accessLevel: parseInt(accessLevel)
        });
    }

    showFiles = () => {

        this.setState({
            navState: NavStates.MAP_FILES
        });

        //this.refs.mapFiles.reloadPage();
    }

    showPipeline = () => {
        this.setState({
            navState: NavStates.PIPELINE
        });

        //this.refs.mapFiles.reloadPage();
    }

    showProcess = () => {

        this.setState({
            navState: NavStates.PROCESS
        });

        //this.refs.mapFiles.reloadPage();
    }

    showWorkflows = () => {

        this.setState({
            navState: NavStates.WORKFLOWS
        });

        //this.refs.mapFiles.reloadPage();
    }

    showCategories = () => {
        this.setState({
            navState: NavStates.CATEGORIES
        });
    }

    showHistory = () => {
        this.setState({
            navState: NavStates.HISTORY
        });
    }

    showProfile = () => {
        this.setState({
            navState: NavStates.PROFILE
        });
    }

    showUsers = () => {
        this.setState({
            navState: NavStates.USERS
        });
    }

    showMap = () => {
        this.setState({
            mapFile: null,
            navState: NavStates.MAP
        });
    }

    openMap = file => {

        this.refs.map.resetMap();

        this.setState({
            mapFile: file,
            navState: NavStates.MAP
        });

        this.refs.topBar.showMapButton();
        this.refs.map.openMap(file);
    }

    setupComplete = () => {
        this.setState({
            accessLevel: 0
        });
    }

    setupMsg = (msg) => {
        this.setState({
            errorMsg: msg
        });
    }

    handleMapUploadEvent = (e) => {
        var objUpload = JSON.parse(e.data);

        //console.log(objUpload);

        if (1 === objUpload.complete)
        {
            if (this.state.activeTransferVisible)
            {
                //console.log("Upload complete");
                this.setState({
                    activeTransferVisible: false,
                    activeTransferPercentage: 100,
                    activeTransferText: 'Upload ' + objUpload.filename + ' Complete'
                });

                if (this.state.navState === NavStates.MAP_FILES)
                {
                    //console.log("Reloading page");
                    this.refs.mapFiles.reloadPage();
                }
            }
        }
        else
        {
            this.setState({
                activeTransferVisible: true,
                activeTransferPercentage: Math.round(100.0 * objUpload.bytesTransferred/objUpload.fileSize),
                activeTransferText: 'Uploading ' + objUpload.filename + ' ' + this.formatBytes(objUpload.bytesTransferred) + ' of ' + this.formatBytes(objUpload.fileSize)
            });
        }
    }

    formatBytes = (bytes, decimals = 2) =>
    {
        if (bytes === 0) return '0 Bytes';

        const k = 1000;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    startEventSource(coinType: string) {
        this.eventSource = new EventSource('map_events');
        this.eventSource.onmessage = e => this.handleMapUploadEvent(e);
    }

    getPageBody = (navState) => {
        var bodyHtml;

        switch (navState)
        {
        case NavStates.MAP_FILES:
        {
            if (this.state.activeTransferVisible)
            {
                bodyHtml = (
                    <Row className="mainBodyMin">
                        <Col>
                            <Map ref="map" isHidden={true} mapFile={this.state.mapFile} />
                            <CloudFiles ref="mapFiles" openMap={this.openMap} accessLevel={this.state.accessLevel} />
                        </Col>
                    </Row>
                );
            }
            else
            {
                bodyHtml = (
                    <Row className="mainBody">
                        <Col>
                            <Map ref="map" isHidden={true} mapFile={this.state.mapFile} />
                            <CloudFiles ref="mapFiles" openMap={this.openMap} accessLevel={this.state.accessLevel} />
                        </Col>
                    </Row>
                );
            }
            break;
        }

        case NavStates.PIPELINE:
        {
            if (this.state.activeTransferVisible)
            {
                bodyHtml = (
                    <Row className="mainBodyMin">
                        <Col>
                            <Map ref="map" isHidden={true} mapFile={this.state.mapFile} />
                            <Pipeline ref="mapFiles" openMap={this.openMap} accessLevel={this.state.accessLevel} />
                        </Col>
                    </Row>
                );
            }
            else
            {
                bodyHtml = (
                    <Row className="mainBody">
                        <Col>
                            <Map ref="map" isHidden={true} mapFile={this.state.mapFile} />
                            <Pipeline ref="mapFiles" openMap={this.openMap} accessLevel={this.state.accessLevel} />
                        </Col>
                    </Row>
                );
            }
            break;
        }

        case NavStates.PROCESS:
        {
            if (this.state.activeTransferVisible)
            {
                bodyHtml = (
                    <Row className="mainBodyMin">
                        <Col>
                            <Map ref="map" isHidden={true} mapFile={this.state.mapFile} />
                            <Process ref="mapFiles" openMap={this.openMap} accessLevel={this.state.accessLevel} />
                        </Col>
                    </Row>
                );
            }
            else
            {
                bodyHtml = (
                    <Row className="mainBody">
                        <Col>
                            <Map ref="map" isHidden={true} mapFile={this.state.mapFile} />
                            <Process ref="mapFiles" openMap={this.openMap} accessLevel={this.state.accessLevel} />
                        </Col>
                    </Row>
                );
            }
            break;
        }

        case NavStates.WORKFLOWS:
        {
            if (this.state.activeTransferVisible)
            {
                bodyHtml = (
                    <Row className="mainBodyMin">
                        <Col>
                            <Map ref="map" isHidden={true} mapFile={this.state.mapFile} />
                            <Workflows ref="mapFiles" openMap={this.openMap} accessLevel={this.state.accessLevel} />
                        </Col>
                    </Row>
                );
            }
            else
            {
                bodyHtml = (
                    <Row className="mainBody">
                        <Col>
                            <Map ref="map" isHidden={true} mapFile={this.state.mapFile} />
                            <Workflows ref="mapFiles" openMap={this.openMap} accessLevel={this.state.accessLevel} />
                        </Col>
                    </Row>
                );
            }
            break;
        }

        case NavStates.CATEGORIES:
        {
            if (this.state.activeTransferVisible)
            {
                bodyHtml = (
                    <Row className="mainBodyMin">
                        <Col>
                            <Map ref="map" isHidden={true} mapFile={this.state.mapFile} />
                            <SettingsPage accessLevel={this.state.accessLevel} />
                        </Col>
                    </Row>
                );
            }
            else
            {
                bodyHtml = (
                    <Row className="mainBody">
                        <Col>
                            <Map ref="map" isHidden={true} mapFile={this.state.mapFile} />
                            <SettingsPage accessLevel={this.state.accessLevel} />
                        </Col>
                    </Row>
                );
            }
            break;
        }

        case NavStates.HISTORY:
        {
            if (this.state.activeTransferVisible)
            {
                bodyHtml = (
                    <Row className="mainBodyMin">
                        <Col>
                            <Map ref="map" isHidden={true} mapFile={this.state.mapFile} />
                            <HistoryPage />
                        </Col>
                    </Row>
                );
            }
            else
            {
                bodyHtml = (
                    <Row className="mainBody">
                        <Col>
                            <Map ref="map" isHidden={true} mapFile={this.state.mapFile} />
                            <HistoryPage />
                        </Col>
                    </Row>
                );
            }
            break;
        }

        case NavStates.PROFILE:
        {
            if (this.state.activeTransferVisible)
            {
                bodyHtml = (
                    <Row className="mainBodyMin">
                        <Col>
                            <Map ref="map" isHidden={true} mapFile={this.state.mapFile} />
                            <ProfileEdit />
                        </Col>
                    </Row>
                );
            }
            else
            {
                bodyHtml = (
                    <Row className="mainBody">
                        <Col>
                            <Map ref="map" isHidden={true} mapFile={this.state.mapFile} />
                            <ProfileEdit />
                        </Col>
                    </Row>
                );
            }
            break;
        }

        case NavStates.MAP:
        {
            if (this.state.activeTransferVisible)
            {
                bodyHtml = (
                    <Row className="mainBodyMin">
                        <Col>
                            <Map ref="map" isHidden={false} mapFile={this.state.mapFile} />
                        </Col>
                    </Row>
                );
            }
            else
            {
                bodyHtml = (
                    <Row className="mainBody">
                        <Col>
                            <Map ref="map" isHidden={false} mapFile={this.state.mapFile} />
                        </Col>
                    </Row>
                );
            }
            break;
        }

        case NavStates.USERS:
        {
            if (this.state.activeTransferVisible)
            {
                bodyHtml = (
                    <Row className="mainBodyMin">
                        <Col>
                            <Map ref="map" isHidden={true} mapFile={this.state.mapFile} />
                            <UserPage />
                        </Col>
                    </Row>
                );
            }
            else
            {
                bodyHtml = (
                    <Row className="mainBody">
                        <Col>
                            <Map ref="map" isHidden={true} mapFile={this.state.mapFile} />
                            <UserPage />
                        </Col>
                    </Row>
                );
            }
            break;
        }

        default:
            bodyHtml = (
                <Row className="mainBody">
                    <Col>
                    </Col>
                </Row>
            );
            break;
        }

        return (bodyHtml);
    }

    getActiveTransferBody = () => {
        var bodyHtml;

        bodyHtml = (
            <Row className="mainBottomButtonExpanded">
                <Col>
                    <Container>
                        <Row>
                            <Col><ProgressBar className="prgUpload" animated now={this.state.activeTransferPercentage} label={this.state.activeTransferText}/></Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        );

        /*
        if ((this.state.activeTransferVisible) && (NavStates.MAP !== parseInt(this.state.navState)))
        {
            bodyHtml = (
                <Row className="mainBottomButtonExpanded">
                    <Col>
                        <Container>
                            <Row>
                                <Col><ProgressBar className="prgUpload" animated now={this.state.activeTransferPercentage} label={this.state.activeTransferText}/></Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            );
        }
        else
        {
            bodyHtml = (
                <Row className="mainBottomButton">
                    <Col>
                    </Col>
                </Row>
            );
        }
        */
        return (bodyHtml);
    }

    getBodyClassName = () => {
        if (this.state.activeTransferVisible)
            return "mainBodyMin";
        else
            return "mainBody";
    }

    render() {
        const navState = parseInt(this.state.navState);
        const accessLevel = parseInt(this.state.accessLevel);

        const html = (
            <Container fluid>
                <Row>
                    <Col><TopBar ref="topBar"
                                 showFiles={this.showFiles}
                                 showPipeline={this.showPipeline}
                                 showProcess={this.showProcess}
                                 showWorkflows={this.showWorkflows}
                                 showCategories={this.showCategories}
                                 showHistory={this.showHistory}
                                 showProfile={this.showProfile}
                                 showUsers={this.showUsers}
                                 showMap={this.showMap}
                                 isLoggedOut={this.isLoggedOut}
                                 username={this.state.username}
                                 accessLevel={this.state.accessLevel} />
                    </Col>
                </Row>
                {this.getPageBody(navState)}
                {this.getActiveTransferBody()}
            </Container>
        );

        if (this.state.userValid)
        {
            return html;
        }
        else
        {
            if (accessLevel >= 0)
            {
                return (
                    <Container className="loginContainer">
                    <Row>
                        <Col><Login isLoggedIn={this.isLoggedIn}/></Col>
                    </Row>
                    </Container>
                );
            }
            else
            {
                return (
                    <Container>
                    <Row>
                        <Col><Setup setupComplete={this.setupComplete}
                                    setupMsg={this.setupMsg}
                                    errorMsg={this.state.errorMsg} /></Col>
                    </Row>
                    </Container>
                );
            }
        }
    }
}

export default App;
