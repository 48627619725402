import React, { Component } from 'react';
import { Button, Modal, Form, Col } from 'react-bootstrap';
import Select from 'react-select'
import './UserUpdate.css';

class UserUpdate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            categories: [],
            errMsg: ''
        };

        fetch('category_list', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: ""
        })
        .then(res => res.json())
        .then((data) => {
            //console.log("category data");
            //console.log(data);
            var selectOpts = [];
            for (var i = 0; i < data.length; i++)
            {
                var opt = {
                    value: data[i].categoryId,
                    label: data[i].title
                };
                selectOpts.push(opt);
                //console.log(data[i].categoryId + " - " + data[i].title);
            }
            //console.log("theLot");
            //console.log(selectOpts);
            this.setState({ categories: selectOpts });
        })
        .catch(console.log)
    }

    handleSubmit = (event) => {
        //const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        fetch('user_add', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: "username=" + this.state.username + "&password=" + this.state.password + "&firstName=" + this.state.firstName + "&lastName=" + this.state.lastName + "&email=" + this.state.email + "&phone=" + this.state.phone
        })
        .then(res => res.json())
        .then(data =>
        {
            if ('true' === data[0].add)
            {
                this.setState({
                    password: '',
                    errMsg: data[0].msg
                });

                this.props.reloadUsers();
            }
            else
            {
                this.setState({
                    errMsg: data[0].msg
                });
            }
        });
    }

    renderDefaultCategories = () => {
        //console.log("renderDefaultCategories");
        //console.log(this.props.userToUpdate.categories);

        if ("null" === this.props.userToUpdate.categories)
        {
            //console.log("categories are null");
            return [];
        }
        else
        {
            //console.log("categories are not null");
            var selectOpts = [];
            for (var i = 0; i < this.props.userToUpdate.categories.length; i++)
            {
                let cat = this.props.userToUpdate.categories[i];
                 var opt = {
                    value: cat.categoryId,
                    label: cat.title
                };
                selectOpts.push(opt);


                //console.log("cat " + i);
                //console.log(cat);
            }
            return selectOpts;
        }
    }

    render() {

        const dot = (color = 'transparent') => ({
            alignItems: 'center',
            //display: 'flex',

            ':before': {
                backgroundColor: color,
                borderRadius: 10,
                content: '" "',
                display: 'block',
                marginRight: 8,
                height: 10,
                width: 10,
            },
        });

        const colourStyles: StylesConfig<ColourOption> = {
            control: (styles) => ({ ...styles, backgroundColor: 'white' }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {

                return {
                ...styles,
                backgroundColor: isDisabled
                    ? undefined
                    : isSelected
                    ? 'red'
                    : isFocused
                    ? '#d90023'
                    : undefined,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                    ? 'black'
                    : '#000000',
                cursor: isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled
                    ? isSelected
                        ? 'red'
                        : '#cf6d7d'
                    : undefined,
                },
                };
            },
            input: (styles) => ({ ...styles, ...dot() }),
            placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
            singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
        };

        return (
            <Modal
              show={this.props.showUpdateUser}
              onHide={this.props.hideUpdateUser}
              backdrop="static"
              centered
            >
            <Modal.Header closeButton>
                <Modal.Title><center><h1 class="pageTitle">Update User</h1></center></Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div>
                <Form className="UserAddForm" onSubmit={this.handleSubmit}>
                    <Form.Row>
                        <Form.Group as={Col} controlId="frmUsername">
                            <Form.Label class="UserAddLabel">Username</Form.Label>
                            <Form.Control className="UserAddControl" type="text" placeholder="Username" value={this.props.userToUpdate.username} onChange={this.handleUsernameChange}/>
                        </Form.Group>

                        <Form.Group as={Col} controlId="frmPassword">
                            <Form.Label class="UserAddLabel">Password</Form.Label>
                            <Form.Control className="UserAddControl" type="password" placeholder="Password" value=""/>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="frmFirstName">
                            <Form.Label class="UserAddLabel">First Name</Form.Label>
                            <Form.Control className="UserAddControl" type="text" placeholder="First Name" value={this.props.userToUpdate.firstName} onChange={this.props.handleUserUpdateFirstName} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="frmLastName">
                            <Form.Label class="UserAddLabel">Last Name</Form.Label>
                            <Form.Control className="UserAddControl" type="text" placeholder="Last Name" value={this.props.userToUpdate.lastName} onChange={this.props.handleUserUpdateLastName} />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="frmEmail">
                            <Form.Label class="UserAddLabel">Email Address</Form.Label>
                            <Form.Control className="UserAddControl" type="email" placeholder="Email Address" value={this.props.userToUpdate.email} onChange={this.props.handleUserUpdateEmail}/>
                        </Form.Group>

                        <Form.Group as={Col} controlId="frmPhone">
                            <Form.Label class="UserAddLabel">Phone</Form.Label>
                            <Form.Control className="UserAddControl" type="text" placeholder="Phone Number" value={this.props.userToUpdate.phone} onChange={this.props.handleUserUpdatePhone}/>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="frmEmail">
                        <Form.Label class="UserAddLabel">Categories</Form.Label>
                             <Select
                               defaultValue={this.renderDefaultCategories}
                               isMulti
                               name="Categories"
                               options={this.state.categories}
                               styles={colourStyles}
                               onChange={this.props.handleUserUpdateCategories}
                              />
                        </Form.Group>

                    </Form.Row>

                    {/*
                    <Form.Row>
                        <Form.Group as={Col} controlId="frmSubmit" className="UserAddSubmit">
                            <Button variant="danger" size="lg" type="submit">Add</Button>
                            <Form.Label class="UserAddSubmitLabel">{this.state.errMsg}</Form.Label>
                        </Form.Group>
                    </Form.Row>
                    */}
                </Form>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <span className="userAddErr">{this.state.errMsg}</span>
                <Button onClick={this.props.handleUpdateUser}>Update</Button>
                <Button onClick={this.props.hideUpdateUser}>Close</Button>
            </Modal.Footer>
            </Modal>
        )
    }
}

export default UserUpdate;
